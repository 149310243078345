import React, { useEffect } from "react";

import InputField from "../InputField/InputField";
import Button from "../Button/Button";
import { useNewPasswordFormik } from "./useNewPasswordFormik";

import "./NewPasswordForm.scss";

interface NewPasswordFormProps {
  email?: string;
  onFormSubmit: (confirmPassword: any) => void;
}

const NewPasswordForm = (props: NewPasswordFormProps) => {
  const { email, onFormSubmit } = props;

  useEffect(() => {
    formik.setFieldValue("email", email);
  }, [email]);

  const formik = useNewPasswordFormik({
    onSubmit: (values: any) => {
      onFormSubmit(values.confirm_password);
    },
  });

  return (
    <div className="NewPasswordForm">
      <p className="NewPasswordForm__title">Create a new password</p>
      <p className="NewPasswordForm__paragraph">
        Your new password must be different from previously used passwords.
      </p>
      <form className="NewPasswordForm__form" onSubmit={formik.handleSubmit}>
        <div className="NewPasswordForm__input">
          <p className="NewPasswordForm__input-label">Email</p>
          <InputField
            className="NewPasswordForm__input-field"
            name="email"
            disabled
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            value={formik.values.email}
            errorText={
              formik.errors.email && formik.touched.email && formik.errors.email
            }
          />
        </div>{" "}
        <div className="NewPasswordForm__input">
          <p className="NewPasswordForm__input-label">New Password</p>
          <InputField
            className="NewPasswordForm__input-field"
            name="new_password"
            icon
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            errorText={
              formik.errors.new_password &&
              formik.touched.new_password &&
              formik.errors.new_password
            }
          />
        </div>
        <div className="NewPasswordForm__input NewPasswordForm__input2">
          <p className="NewPasswordForm__input-label">Confirm New Password</p>
          <InputField
            className="NewPasswordForm__input-field InputField__screen2k"
            name="confirm_password"
            icon
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            errorText={
              formik.errors.confirm_password &&
              formik.touched.confirm_password &&
              formik.errors.confirm_password
            }
          />
        </div>
        <Button
          type="submit"
          style={{ width: "100%" }}
          onClick={() => {}}
          label="Update Password"
          className="Button__light"
        />
      </form>
    </div>
  );
};

export default NewPasswordForm;
