import React from "react";

import { GlobalContextProvider } from "./context/GlobalContextProvider/GlobalContextProvider";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Email from "./pages/Email/Email";
import NotFound from "./pages/NotFound/NotFound";

import "./App.scss";

function App() {
  return (
    <div className="App">
      <GlobalContextProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/email" element={<Email />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </GlobalContextProvider>
    </div>
  );
}

export default App;
