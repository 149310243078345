import React, { useState } from "react";
import cs from "classnames";

import { IconDefinition } from "../Icon/Icon.generated";
import { Icon } from "../Icon/Icon";
import { Spinner } from "reactstrap";

import "./Button.scss";

interface ButtonProps {
  rightIcon?: IconDefinition;
  leftIcon?: IconDefinition;
  label?: string;
  style?: React.CSSProperties;
  className?: string;
  onClick: () => void;
  toolTipLabel?: string;
  toolTipPosition?: string;
  popOverPosition?: string;
  popOverTitle?: string;
  popOverDescription?: string;
  type?: "submit" | "reset" | "button";
  form?: string;
  isLoading?: boolean;
  id?: string;
  disabled?: boolean;
}

const Button = (props: ButtonProps) => {
  const {
    disabled,
    isLoading,
    rightIcon,
    leftIcon,
    label,
    style,
    className,
    onClick,
    toolTipLabel,
    toolTipPosition,
    popOverPosition,
    popOverTitle,
    popOverDescription,
    type,
    id,
  } = props;

  const [toolTipClassName, setToolTipClassName] = useState<string>();
  const [popOverOpen, setPopOverOpen] = useState(false);

  return (
    <button
      disabled={disabled}
      type={type}
      id={id}
      onClick={() => {
        if (popOverPosition) {
          setPopOverOpen(!popOverOpen);
          return;
        }
        props.onClick();
      }}
      style={style}
      className={cs("Button", className, disabled && `Button--disabled`)}
    >
      <Icon
        onClick={() => console.log("clicked")}
        className="Button__left--icon"
        icon={leftIcon}
      />

      {isLoading ? (
        <Spinner
          animation="border"
          style={{ color: "white" }}
          size="sm"
          variant="primary"
        />
      ) : (
        <>{label}</>
      )}

      <Icon
        onClick={() => console.log("clicked")}
        className="Button__right--icon"
        icon={rightIcon}
      />

      {toolTipLabel && (
        <span
          className={cs(
            "Button__tooltip",
            `Button__tooltip--${toolTipPosition}`,
            `Button__tooltip--${toolTipClassName}`,
          )}
        >
          {toolTipLabel}
        </span>
      )}

      {popOverOpen && popOverPosition && (
        <p
          className={cs(
            "Button__popover",
            `Button__popover--${popOverPosition}`,
          )}
        >
          <span className="Button__popover--title">{popOverTitle}</span>
          <span className="Button__popover--description">
            {popOverDescription}
          </span>
        </p>
      )}
    </button>
  );
};

export default Button;
